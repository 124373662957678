import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import( '../views/InformationView.vue')
  },
  {
    path: '/works',
    name: 'Works',
    component: () => import( '../views/WorksView.vue')
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import( '../views/ShareView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
