<template>
  <div>
    <div class="background"></div>
    <div class="main">
      <div class="navBox">
        <div :class="{ nav: navDefault === i }" v-for="({ name, path }, i) of nav" :key="i"
          @click="$router.push({ path }), navDefault = i">
          <span>{{ name }}</span>
        </div>
      </div>
      <div class="content">
        <router-view class="routerBox"></router-view>
        <bottom-com />
      </div>
    </div>
  </div>
</template>

<script>
import BottomCom from './components/BottomCom.vue'
export default {
  components: { BottomCom },
  data() {
    return {
      nav: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '资料',
          path: '/information'
        },
        {
          name: '作品',
          path: '/works'
        },
        {
          name: '分享',
          path: '/share'
        }
      ],
      navDefault: 0
    }
  },

}
</script>

<style lang="less" scoped>
.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: url('@/assets/images/bj3.webp');
  filter: brightness(30%);
  background-size: 100% 100%;
  // opacity: 1;
}

.routerBox {
  min-height: calc(100vh - 110px);
}

.main {
  width: 1200px;
  margin: 0 auto;
}

.content {
  overflow-y: scroll;
  height: calc(100vh - 60px);


}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
  background-color: #00000017;
  scrollbar-color: #50b4ff #2c60c5;
  // scrollbar-width: auto/thin/none/自定义宽度；
  // none-隐藏滚动条
  scrollbar-width: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.navBox {
  background: rgba(0, 0, 0, 0.5);
  height: 60px;
  display: flex;
  padding: 0 60px;

  >div {
    padding: 0 20px;
    line-height: 60px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .nav {
    color: rgb(0, 110, 255);
    background: rgba(0, 0, 0, 1);
  }
}
</style>
<style>
#app {
  color: #fff;
  padding: 0;
  margin: 0;

}
</style>