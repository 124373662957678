<template>
    <div class="bottom">
        <div>
            <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2022028988号-1</a>
        </div>
        <div>©2022-2023</div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
.bottom{
    height: 50px;
    background-color: rgba(0,0,0,0.1);
    text-align: center;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a{
        color: #FFF;
        text-decoration: none;
        font-size: 14px;
    }
}
</style>