<template>
  <div class="box">
    <div class="userImg">
      <img src="@/assets/images/user_img.jpg" alt="">
    </div>
    <div class="name">前端那片海</div>
    <div class="info">
      <p>只要认为是对的就去做，坚持去做，不要在乎别人的看法，哪怕是错，至少你有去做过证明曾经你努力过。</p>
    </div>
    <div class="label">
      <div v-for="({label},i) of labelList" :key="i">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
data(){
  return {
    labelList:[
      {
        label:'码到成功'
      },
      {
        label:'码虎不得'
      },
      {
        label:'码到成功'
      },
    ]
  }
}
}
</script>

<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;

  }
}

.name {
  font-size: 14px;
  margin: 5px 0;
}
.info{
  width: 400px;
  font-size: 12px;
  margin: 50px 0 20px;
}
.label{
  display: flex;
  >div{
  margin-left: 20px;
    border: 1px solid #FFF;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 50px;
  }
}
</style>
